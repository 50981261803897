module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Eraville Concept Web","short_name":"ECW","description":"Freelance Développeur PHP et Intégrateur Web. Wordpress (E-commerce), JAM Stack, REACT","lang":"fr","start_url":"/","background_color":"#c4b583","theme_color":"#c4b583","display":"minimal-ui","icon":"src/images/logo_ecw.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"290ca01623da2e0b4741191c8df7a1a7"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-132881544-1"},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
